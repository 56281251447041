import React from 'react';
import Seo from '../components/seo';
import Layout from '../components/layout';

import Container from '../components/container';

class PageNotFoundPage extends React.Component {
  render() {

    return (
      <Layout location={this.props.location}>
        <Seo title="404" />
        <Container>
        <h1>Sidan kunde inte hittas!</h1>
        <p>Sidan kunde tyvärr inte hittas, den kan ha flyttats eller tagits bort.</p>
        </Container>

  
      </Layout>
    )
  }
}

export default PageNotFoundPage;
